import React from 'react';
import './Testimonials.css'; // Link to your custom CSS file

const Testimonials = () => {
    return (
        <section className="testimonial-section"
            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/image/eligibility-bg.png)` }}>
            <div className="testimonial-header">
                <h2>What our Students Think About Us</h2>
                <p>We are excited to have contributed to over 5,000+ successful student journeys. Listen to some of their stories!</p>
            </div>

            <div className="testimonial-cards">
                <div className="testimonial-card">
                    <div className="testimonial-content">
                        <span className="quote-icon">“</span>
                        <p>I am highly grateful to Aman for ensuring me getting an education loan deal in all possible ways. He remained ever courteous, polite, and professional despite all my delays and scolding from some of his high authorities for the same. His dedication and persistent efforts helped him get an offer for me with best possible available terms. I truly appreciate how he managed everything with great care and much understanding-most especially when I took too long a time to respond. He made extra effort to guide me through
                            the whole process, ensuring that everything was fine. It really makes a big difference, and personally, I would want to thank him for the good service and commitment that he gave me. Highly recommended!</p>
                    </div>
                    <div className="testimonial-footer">
                        <img src="../../image/nithin.png" alt="Patel Pooja" />
                        <div>
                            <span>Nathi Sudheer</span>
                            <br />
                            <span><em>USA</em></span>
                        </div>
                    </div>
                </div>

                <div className="testimonial-card">
                    <div className="testimonial-content">
                        <span className="quote-icon">“</span>
                        <p>It is very good I got my loan sanction letter within 3 days. Himani mam helped me  in all the possible ways to get my loan sanction as as possible. She quickly reacted to all the queries and cleared them within no time</p>
                    </div>
                    <div className="testimonial-footer">
                        <img src="../../image/ramesh.png" alt="Harsh Damor" />
                        <div>
                            <span>Ramesh Kumar Reddy</span>
                            <br />
                            <span><em>USA</em></span>
                        </div>
                    </div>
                </div>

                <div className="testimonial-card">
                    <div className="testimonial-content">
                        <span className="quote-icon">“</span>
                        <p>I recently secured an education loan through Student's choice consultancy with HDFC CREDILA financial services limited , and I'm thoroughly impressed by their streamlined and simplified process. The team was knowledgeable and supportive, guiding me through every step with clear explanations and timely advice. Their commitment to making education accessible really shone through their professional handling of every query, which significantly reduced the stress around financing my studies. I'm grateful for their help, which has allowed me to focus more on my academics. I highly recommend Student's choice to anyone seeking hassle-free assistance with education loans.</p>
                    </div>
                    <div className="testimonial-footer">
                        <img src="https://mail.google.com/mail/u/0?ui=2&ik=9ba2a3e3fb&attid=0.3&permmsgid=msg-f:1812102613225154105&th=1925e1b9cdd7a639&view=fimg&fur=ip&sz=s0-l75-ft&attbid=ANGjdJ8PIwVigICSRRDeZM95mHW3ytC_npX5qYphZuRiVOUxGLvIXKrC3lj0efEvIbYPtlkgFMswcxnj6JYVCoFPHOiDpzVqSFJ2Oh06VKVVgWBBKaux-ZbUZnS8BNk&disp=emb&realattid=ii_m1wj96ej2" alt="Kashish Rakholiya" />
                        <div>
                            <span>Venu kumar</span>
                            <br />
                            <span>USA</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;