import React from 'react';
import './FooterMoving.css'; // Import custom CSS
import Marquee from './Marquee';

const FooterMoving = () => {
    return (
        <div className="footer-animation-container">
            <div className="footer-animation">
                {/* <img
                    src={`${process.env.PUBLIC_URL}/image/moving3.svg`}  // Replace with the correct path to your moving background image
                    alt="Footer Background"
                    className="moving-background"
                /> */}
                <Marquee/>
                <img
                    src={`${process.env.PUBLIC_URL}/image/aeroplane.png`}  // Replace with the correct path to your airplane image
                    alt="Airplane"
                    className="static-airplane"
                />
            </div>
        </div>
    );
};

export default FooterMoving;
