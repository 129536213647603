import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/home/landingPage';
import Header from './components/home/Header';
import './App.css';
import Footer from './components/footer/Footer';
import FooterMoving from './components/footer/FooterMoving';
import LoanEligibility from './components/services/eligibility/LoanEligibility';
import ContactUs from './components/contactUs/ContactUs';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/loan-eligibility" element={<LoanEligibility/>} />
          <Route path="/contact-us" element={<ContactUs/>} />

          {/* Add more routes here as needed */}
        </Routes>
        
        <Footer />
        <FooterMoving />
      </div>
    </Router>
  );
}

export default App;