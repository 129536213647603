import React, { useState } from 'react';
import './LoanPartners.css';

const LoanPartners = () => {
  // State to manage whether to show more or not
  const [showAll, setShowAll] = useState(false);

  // List of partners (Example image paths)
  const partners = [
    {
      id: 1,
      imgSrc: `${process.env.PUBLIC_URL}/image/icici.png`,
      altText: 'ICICI Bank',
      link: 'https://unicreds.com/partners/icici-bank',
      interestRate: '10.25%',
    },
    {
      id: 2,
      imgSrc: `${process.env.PUBLIC_URL}/image/hdfc-credila.png`,
      altText: 'HDFC Credila',
      link: 'https://unicreds.com/partners/hdfc-credila',
      interestRate: '10.00%',
    },
    {
      id: 3,
      imgSrc: `${process.env.PUBLIC_URL}/image/avanse.png`,
      altText: 'Avanse',
      link: 'https://unicreds.com/partners/avanse',
      interestRate: '10.9%',
    },
    {
      id: 4,
      imgSrc: `${process.env.PUBLIC_URL}/image/union.png`, // Use backticks without quotes
      altText: 'Union Bank of India',
      link: 'https://unicreds.com/partners/union-bank-of-india',
      interestRate: '9.5%',
    },
    {
      id: 5,
      imgSrc: `${process.env.PUBLIC_URL}/image/incred.png`,
      altText: 'InCred',
      link: 'https://unicreds.com/partners/incred',
      interestRate: '11.25%',
    },
    {
      id: 6,
      imgSrc: `${process.env.PUBLIC_URL}/image/idfc.png`,
      altText: 'IDFC Bank',
      link: 'https://unicreds.com/partners/idfc',
      interestRate: '10.00%',
    },
    {
      id: 7,
      imgSrc: `${process.env.PUBLIC_URL}/image/yes.png`,
      altText: 'Yes Bank',
      link: 'https://unicreds.com/partners/yes-bank',
      interestRate: '10.25%',
    },
    {
      id: 8,
      imgSrc: `${process.env.PUBLIC_URL}/image/auxilo.png`,
      altText: 'Auxilo',
      link: 'https://unicreds.com/partners/auxilo',
      interestRate: '11.00%',
    },
    {
      id: 9,
      imgSrc: `${process.env.PUBLIC_URL}/image/tata.png`,
      altText: 'Tata Capital',
      link: 'https://unicreds.com/partners/tata-capital',
      interestRate: '11.50%',
    },
    {
      id: 10,
      imgSrc: `${process.env.PUBLIC_URL}/image/prodigy.png`,
      altText: 'Prodigy Finance',
      link: 'https://unicreds.com/partners/prodigy-finance',
      interestRate: '11.75%',
    },
  ];

  // Handler for the "View More" button
  const handleViewMore = () => {
    setShowAll(true);
  };

  return (
    <div className="LoanPartners_loanPartners">
      <div className="LoanPartners_heading">
        <h2>Our Trusted Loan Partners</h2>
        <p >Explore Your Options: Competitive Interest Rates from Over 10+ Lenders.
        </p>
      </div>

      <div className="LoanPartners_partnersCards">
        {/* Display 4 partners initially, show all if "showAll" is true */}
        {partners.slice(0, showAll ? partners.length : 4).map((partner) => (
          <div key={partner.id} className="LoanPartners_card">
            <img src={partner.imgSrc} alt={partner.altText} />
            <a href={partner.link} target="_blank" rel="noopener noreferrer">
            Interest rate{partner.interestRate} p.a* 
            </a>
          </div>
        ))}
      </div>

      {/* Show the "View More" button only if not all partners are visible */}
      {!showAll && (
        <div className="LoanPartners_viewMore">
          <button onClick={handleViewMore}>View More <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.296 8.294Q6.591 8 7.049 8q.457 0 .754.294L12 12.462l4.197-4.168Q16.494 8 16.951 8t.753.294.296.748-.296.748l-4.95 4.916a.95.95 0 0 1-.35.228Q12.216 15 12 15q-.215 0-.404-.066a.95.95 0 0 1-.35-.228L6.297 9.79A1 1 0 0 1 6 9.042q0-.454.296-.748" fill="#12284C"></path></svg></button>
        </div>
      )}
    </div>
  );
};

export default LoanPartners;
