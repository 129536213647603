// src/components/MainSection.js
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import ContactUsModal from "../contact/ContactUsModal";

import './MainSection.css';


const MainSection = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <section className="landing-page">
      <div className="main-container">
        <div className="text-continer">
          <p className='landing-p'>Discover the Students Choice Advantage, Affordable, Transparent, and Comprehensive Study Abroad Services
          </p>

          <h1>Education Loan for Abroad Studies Made Hassle-Free</h1>

          <div className="buttons">
            <button className="talk-to-counsellors" onClick={() => setIsModalOpen(true)}>Talk to Our Counsellors</button>
            {/* <button className="apply-now">Apply Now</button> */}
            <ContactUsModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
            <Link to="/contact-us">
              <button className="apply-now apply-btn">
                Apply Now
              </button>
            </Link>

          </div>

          <div className="googleReviews">
            <h3>Our Achievements</h3>
            <div className="google-star">
              <div className='googleIcon'>
                <img src="../../image/googleIcon.png" alt="Google" />
              </div>
              <div>
                <p className='upStar'>Google Reviews</p>
                <div className="rating">
                  <span className="star"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="image-content">
          <img className='landing-img' src="../../image/landingImage.png" alt="Student" />
        </div>

      </div>
    </section>
  );
};

export default MainSection;
