import React, { useState } from "react";
import "./FAQSection.css"; // Corrected CSS path

const FAQSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqItems = [
        {
            question: "What is the process of applying for a Education loan with Students Choice?",
            answer: "The process of applying for an education loan is extremely simple and free of cost. Just fill in some preferences details on the platform and you will get an offer. Avail the offer and shared the required documents - voila you have finished the procedure just relax because you will get the approval in a week. So, what are you waiting for? Continue your process on https://studentschoice.in/",
        },
        {
            question: "I have my visa interview very soon, how quickly can I get a Education loan?",
            answer: `If you have completed your loan application on the platform and shared all the required documents we will log in your file with our best lenders within 24 hrs of your submission, the profile evaluation takes 1-2 days and immediately after this - you can get a provisional approval. So within 48hrs if everything is aligned, you will have your provisional sanction letter from a lender. Please go ahead and avail of the offer and shared the required documents, and check fast-track your application process.`,
        },
        {
            question: "I do not have a Co-applicant document, what should I do now?",
            answer: "We can Apply the loan with lenders who do not require a co-applicant. So don’t worry just go ahead, avail of the offer shown on the platform, and shared all the documents related to your profile, in the co-applicant slots just shared some blank documents for now and move ahead.",
        },
        {
            question: "Are Non-Collateral and private lenders trustworthy?",
            answer: "Yes, Non-Collateral private and international lenders are trustworthy. They offer better loan amounts at lower interest rates to students. All are diligently registered and regulated by the governing bodies. So, don't let this worry you, continue your process on https://studentschoice.in/",
        },
        {
            question: "How is an offer calculated?",
            answer: "Your loan amount is calculated on the basis of the following: Loan Type, University Ranking, Entrance Score, Stem/Non-Stem Program, and English Score. If you want to negotiate your offer, our Loan Officers will do that for you. Continue your process at https://studentschoice.in/",
        },
        {
            question: "How much do lenders offer, and at what interest rates?",
            answer: "Lenders offer education loans based on various factors such as your course, country, and financial background. Generally, the loan amount can range from ₹5 lakh to ₹1 crore for studies abroad, with some lenders providing up to 100% of the cost of attendance (including tuition, living expenses, etc.). Interest rates typically fall between 7% and 13% per annum, depending on the lender, loan type (secured vs. unsecured), and your profile. Secured Loans (with collateral): Lower interest rates, typically between 9.5-10.5%. Unsecured Loans (without collateral): Higher interest rates, ranging from 10.5-13%. In case you are not satisfied with the lender's proposed terms like Interest and processing fee- we will negotiate on your profile. Collateral interest rates start from 9.5% and non-collateral start from 10.5%. So, don't let this worry you, continue your process at https://studentschoice.in/",
        },
        {
            question: "Who can be my Co-Applicant?",
            answer: "Your co-applicant can be any of your blood relatives who have financial documents like an ITR, Pay Slip, Income Certificate others- parents, uncle/aunt, siblings, in-laws, cousins, etc. They must be filing ITR for at least 1 year. However, this will vary from lender to lender, our Loan officer will match you with the lender based on the co-applicant of your choice.",
        },
        {
            question: "What are the lists of documents required to complete the process upon availing of the offer?",
            answer: "In Identity docs, we require your Passport and PAN, Aadhar. In Academic - we require you to upload your 10th, 12th, and Bachelor’s Marksheet, Admit card (Only if you have received). In the Financial – Latest 6 Months Bank statement, Salary slip, and IT returns for the last 1 year (if employed). For Co-applicant documents, we require PAN, Aadhar, Bank Statement/Salary Slip, Business/House proof, and IT returns for the last 1 year.",
        },
        {
            question: "How is the disbursement made?",
            answer: "Loan is disbursed in INR and transferred to the Student’s or co-applicant’s bank account and the college/university directly, as per the requirement.",
        },
        {
            question: "Can students pre-pay their Loan?",
            answer: "When students repay their loan after 6 months of the disbursement date, no charges will be levied for early closure of the education loan.",
        },
        {
            question: "Why should I choose Students Choice instead of directly going to the bank?",
            answer: "Students Choice connects you with leading lenders through a single application. By negotiating for groups of students, we secure lower interest rates compared to individual applicants. We also save you time by handling all communication with lenders, ensuring your application progresses smoothly. With us, you can receive a provisional loan sanction within 48 hours. Why wait? Start your application process now at https://studentschoice.in/",
        },
        {
            question: "I want to talk to an Loan Officer urgently. What is the fastest way to connect with them?",
            answer: "If you want to connect with a Loan Officer urgently, kindly contact the number +91 98018 21680 on WhatsApp, type in your name, and ask your query. We will reply to you within an hour. So, don't hesitate. Contact us now.",
        },
    ];
    

    return (
        <div className="faq-section">
            <div className="faq-header">
                <div className="faq-line" />
                <h2>Frequently Asked Questions</h2>
                <div className="faq-line"></div>
            </div>
            <div className="faq-items">
                {faqItems.map((item, index) => (
                    <div
                        className={`faq-item ${activeIndex === index ? "active" : ""}`}
                        key={index}
                    >
                        <div
                            className="faq-question"
                            onClick={() => toggleAccordion(index)}
                        >
                            <p>{item.question}</p>
                            <span className="faq-icon">
                                {activeIndex === index ? "−" : "+"}
                            </span>
                        </div>
                        <div
                            className={`faq-answer ${activeIndex === index ? "show" : ""}`}
                        >
                            <p>{item.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQSection;
