import React from 'react';
import './Footer.css';
import WhatsAppButton from './WhatsAppButton';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-contact">
                    <div className="contact-item">
                        <WhatsAppButton />
                    </div>
                    {/* <div className="contact-item">
                        <i className="fas fa-envelope"></i>
                        <span>info@studentschoice.in</span>
                    </div> */}
                    <div className="footer-social">
                        <span>Follow us on:</span>
                        <div className="social-icons">
                            <a href="https://www.linkedin.com/company/student-s-choice/"><img src={`${process.env.PUBLIC_URL}/image/linkedin.png`} alt="Linkedin" /></a>
                            <a href="www.youtube.com/@Students_Choice_Consultancy"><img src={`${process.env.PUBLIC_URL}/image/youtube.png`} alt="Youtube" /></a>
                            <a href="https://www.instagram.com/students_choice_consultancy?igsh=bmszaTdwMXgwaHU1&utm_source=qr"><img src={`${process.env.PUBLIC_URL}/image/insta.png`} alt="Instagram" /></a>
                        </div>
                    </div>
                </div>

                <div className="footer-main">
                    <div className="footer-section logo-section">
                        <img src="../../image/logo.png" alt="Students Choice Logo" className="footer-logo" />
                        <p>
                            Our mission is to empower every individual to plan the future they deserve. We are offering
                            India's first digital platform catering to education planning for every Indian.
                        </p>
                    </div>

                    <div className="footer-section">
                        <h3>Quick Links</h3>
                        <ul>
                            <li>Student Service</li>
                            <li>Study Abroad</li>
                            <li>Contact Us</li>
                            <li>About Us</li>
                        </ul>
                    </div>

                    <div className="footer-section">
                        <h3>About Us</h3>
                        <ul>
                            <li>Privacy Policy</li>
                            <li>Terms & Conditions</li>
                            <li>Our Team</li>
                            <li>Testimonials</li>
                        </ul>
                    </div>

                    {/* <div className="footer-section">
                        <h3>Our Partners</h3>
                        <ul>
                            <li>HDFC Credila</li>
                            <li>Avanse</li>
                            <li>Union Bank</li>
                            <li>IDFC First Bank</li>
                        </ul>
                    </div> */}
                </div>


            </div>
        </footer>
    );
};

export default Footer;