import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import emailjs from 'emailjs-com'; // Import EmailJS
import './ContactUsModal.css';

const countries = [
  'United States', 'Canada', 'Mexico', 'United Kingdom', 'Germany',
  'France', 'Italy', 'Spain', 'Australia', 'India','America',
  // Add more countries as needed
];

const ContactUsModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    message: '',
    country: '',
  });

  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Send email via EmailJS
      const result = await emailjs.send(
        'service_sc85wtb',      // Your EmailJS Service ID
        'template_imuy3wm',     // Your EmailJS Template ID
        {
          from_name: formData.name,
          from_email: formData.email,
          contact: formData.contact,
          country: formData.country,
          message: formData.message,
        },
        'VbycJqRCfIVQ3Je28'     // Your Public Key
      );

      if (result.status === 200) {
        setStatus('Message sent successfully!');
        setFormData({ name: '', email: '', contact: '', message: '', country: '' });
      } else {
        setStatus('Failed to send message. Please try again.');
      }
    } catch (error) {
      setStatus('Error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : countries.filter((country) =>
          country.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const onCountryChange = (event, { newValue }) => {
    setFormData({ ...formData, country: newValue });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <h2>Connect with Us</h2>
        <p>
          Fill out the form to start your process with us. We'll help you
          explore loan options from our trusted partners or connect you with one
          of our loan officers to guide you through the process.
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="contact"
            placeholder="Contact Number"
            value={formData.contact}
            onChange={handleChange}
            required
          />
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              name: 'country',
              placeholder: 'Planning Country',
              value: formData.country,
              onChange: onCountryChange,
            }}
            theme={{
              container: 'autosuggest-container',
              suggestionsContainer: 'autosuggest-suggestions-container',
              suggestionsList: 'autosuggest-suggestions-list',
              suggestion: 'autosuggest-suggestion',
              suggestionHighlighted: 'autosuggest-suggestion--highlighted',
            }}
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            required
          />
          <button type="submit" className="submit-btn" disabled={loading}>
            {loading ? 'Sending...' : 'Send Message'}
          </button>
        </form>
        {status && <p className="status-message">{status}</p>}
      </div>
    </div>
  );
};

export default ContactUsModal;
