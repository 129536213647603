import React from "react";
import "./Marquee.css";

const Marquee = () => {
//   const imageUrl =
//     "`${process.env.PUBLIC_URL}/image/moving3.svg`"; // Replace with your image URL

  return (
    <div className="marquee-container">
      <div className="marquee-content">
        <img src={`${process.env.PUBLIC_URL}/image/moving3.svg`} alt="Scrolling" />
        
      </div>
    </div>
  );
};

export default Marquee;
