import React from 'react';
import './Eligibility.css'; // Updated CSS file name

const Eligibility = () => {
    return (
        <section className="eligibility-wrapper">
            <div className="eligibility-box">
                <h2 className='eligibility-title'>Are You Eligible?</h2>
                <p className='eligibility-description'>Check Your Eligibility to Get Financial Assistance Plan at Low Cost EMI</p>
                <div className='eligibility-structure'>
                    <div className='eligibility-info'>
                        <ul className="eligibility-checklist">

                            <li className='checklist-item'>
                                <span className='checklist-arrow'>&#11166;</span>
                                <b>Do you have ID proofs?</b>
                                <ul>
                                    <li className='checklist-subitem'>Passport, Aadhar Card, or Pan Card.</li>
                                </ul>
                            </li>
                            <li className='checklist-item'>
                                <span className='checklist-arrow'>&#11166;</span>
                                <b>Do you have an active bank account?</b>
                                <ul>
                                    <li className='checklist-subitem'>Bank Statement</li>
                                </ul>
                            </li>
                            <li className='checklist-item'>
                                <span className='checklist-arrow'>&#11166;</span>
                                <b>Do you have address proofs?</b>
                                <ul>
                                    <li className='checklist-subitem'>Electricity Bill or House Tax</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className='eligibility-image'>
                        <img src={`${process.env.PUBLIC_URL}/image/eligibility.png`} alt="Eligibility" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Eligibility;
