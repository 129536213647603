import React from 'react';
import './CountriesSection.css';

const CountriesSection = () => {
    const countries = [
        {
            name: 'Canada',
            image: '../../image/country/2.png',
            price: '₹20 Lakh',
            savings: '₹4 Lakh',
        },
        {
            name: 'USA',
            image: '../../image/country/1.png',
            price: '₹15 Lakh',
            savings: '₹4 Lakh',
        },
        {
            name: 'UK',
            image: '../../image/country/3.png',
            price: '₹15 Lakh',
            savings: '₹3 Lakh',
        },
        {
            name: 'Australia',
            image: '../../image/country/4.png',
            price: '₹20 Lakh',
            savings: '₹5 Lakh',
        },
        {
            name: 'Germany',
            image: '../../image/country/5.png',
            price: '₹14 Lakh',
            savings: '₹2 Lakh',
        },
        {
            name: 'Ireland',
            image: '../../image/country/6.png',
            price: '₹16 Lakh',
            savings: '₹4 Lakh',
        },
    ];

    return (
        <section className="countries-section">
            <h2 className='country-heading'>Explore the best countries for Study Abroad</h2>
            <div className="countries-grid">
                {countries.map((country, index) => (
                    <div className="country-card" key={index}>
                        <img src={country.image} alt={country.name} className="country-image" />
                        <h3>{country.name}</h3>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default CountriesSection;
