import './ApplyProcess.css'; // Link to the CSS file

const Eligibility = () => {
    return (
        <section className="apply-process">
            <div className="apply-container">
                <h2 className="section-title">Apply With Students Choice Loans</h2>
                <p className="section-description">Step Towards a Brighter Future with Students Choice Loan!</p>
                <div className="process-item">
                    <div className="process-step">
                        <h4>1. Select</h4>
                        <img src={`${process.env.PUBLIC_URL}/image/1.png`} alt="select 1 " />
                        <p>Choose your best 
                        loan partner.</p>
                    </div>
                    <div className="process-step">
                        <h4>2. Apply</h4>
                        <img src={`${process.env.PUBLIC_URL}/image/2.png`} alt="select 1 " />
                        <p>Provide and verify your
                        details and documents online.</p>
                    </div>
                    <div className="process-step">
                        <h4>3. Approval</h4>
                        <img src={`${process.env.PUBLIC_URL}/image/3.png`} alt="select 1 " />
                        <p>On-the-spot approval and instant support from our loan officers.</p>
                    </div>
                    <div className="process-step">
                        <h4>4. Disburse  & Repay</h4>
                        <img src={`${process.env.PUBLIC_URL}/image/4.png`} alt="select 1 " />
                        <p>Loan disbursed to your account as you repay in flexible EMIs.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Eligibility;