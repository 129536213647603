import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
    return (
        <section className="contact-section">
            <div className="contact-header">
                <h2>Begin Your Journey | Secure Your Loan </h2>
                <p>Unlock your educational dreams today—apply now for our competitive education loans, available for both domestic and 
                    international studies, and take the first step towards a brighter future!</p>
            </div>
            <div className="contact-container">
                <div className="contact-image">
                    <img src={`${process.env.PUBLIC_URL}/image/contactus.png`} alt="Contact Us" />
                </div>
                <form className="contact-form">
                    <label htmlFor="name">Name *</label>
                    <input type="text" id="name" name="name" required placeholder="Your Name" />

                    <label htmlFor="email">Email *</label>
                    <input type="email" id="email" name="email" required placeholder="Your Email" />

                    <label htmlFor="phone">Phone Number *</label>
                    <input type="tel" id="phone" name="phone" required placeholder="Your Phone Number" />

                    <label htmlFor="loan-partner">Preferred Loan Partner</label>
                    <select id="loan-partner" name="loan-partner">
                        <option value="">Select...</option>
                        <option value="ICICI">ICICI Bank (10.25% p.a)</option>
                        <option value="HDFC">HDFC Credila (10.00% p.a)</option>
                        <option value="Avanse">Avanse (10.9% p.a)</option>
                        <option value="UnionBank">Union Bank of India (9.5% p.a)</option>
                    </select>

                    <label htmlFor="message">Your Message *</label>
                    <textarea id="message" name="message" rows="5" required placeholder="Your Message"></textarea>

                    <button type="submit" className="submit-btn">Send Message</button>
                </form>
            </div>
        </section>
    );
};

export default ContactUs;
