import React from 'react';
import './LoanEligibility.css';

const LoanEligibility = () => {
  return (
    <section className="loan-eligibility-section">
      <div className="eligibility-container">
        <div className="left-character">
          <img src={`${process.env.PUBLIC_URL}/image/student-reading.svg`} alt="Student Reading" />
        </div>

        <div className="mid-container">
        <div className="left-character">
          <img src={`${process.env.PUBLIC_URL}/image/exchange-value.svg`} alt="exchange value" />
        </div>
            <p className="std-abrd-color">Study Abroad</p>
            <h2>Loan Eligibility</h2>
            <p>
                A tool that checks your eligibility for education loan for studying abroad <br></br>
                and finds the best offer in the international student loan network.
            </p>
        </div>

        <div className="right-character">
          <img src={`${process.env.PUBLIC_URL}/image/graduating-student.svg`} alt="Graduating Student" />
        </div>
      </div>
      <div className="eligibility-content">
          

          <div className="eligibility-icons">
            <div className="icon-item">
              <img src={`${process.env.PUBLIC_URL}/icons/loan-offer-icon.svg`} alt="Loan Offers" />
              <p>Loan offers like students choice</p>
            </div>
            <div className="icon-item">
              <img src={`${process.env.PUBLIC_URL}/icons/eligibility-check-icon.svg`} alt="Eligibility Check" />
              <p>Hassle free Eligibility check</p>
            </div>
            <div className="icon-item">
              <img src={`${process.env.PUBLIC_URL}/icons/zero-cost-icon.svg`} alt="Zero Cost" />
              <p>Zero cost service</p>
            </div>
            <div className="icon-item">
              <img src={`${process.env.PUBLIC_URL}/icons/us-loan-icon.svg`} alt="US Loan Offers" />
              <p>Unique US loan offers</p>
            </div>
          </div>
        </div>
    </section>
  );
};

export default LoanEligibility;
