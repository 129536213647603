// src/App.js
import React from 'react';
import MainSection from './MainSection';
import LoanPartners from './LoanPartners';
import Eligibility from './Eligibility';
import ApplyProcess from './ApplyProcess';
import Testimonials from './Testimonials';
import FAQSection from './FAQSection';
import CountriesSection from './CountriesSection';


function LandingPage() {
  return (
    <div className="LandingPage">
      <MainSection />
      <LoanPartners />
      <Eligibility />
      <ApplyProcess />
      <Testimonials />
      <CountriesSection />
      <FAQSection />
    </div>
  );
}

export default LandingPage;
